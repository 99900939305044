// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("/opt/build/repo/src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-misconceptions-and-misinformation-about-machine-learning-consultants-may-affect-your-business-js": () => import("/opt/build/repo/src/pages/blog/misconceptions-and-misinformation-about-machine-learning-consultants-may-affect-your-business.js" /* webpackChunkName: "component---src-pages-blog-misconceptions-and-misinformation-about-machine-learning-consultants-may-affect-your-business-js" */),
  "component---src-pages-blog-the-importance-of-a-business-transformation-plan-in-implementing-real-change-to-any-business-or-organization-js": () => import("/opt/build/repo/src/pages/blog/the-importance-of-a-business-transformation-plan-in-implementing-real-change-to-any-business-or-organization.js" /* webpackChunkName: "component---src-pages-blog-the-importance-of-a-business-transformation-plan-in-implementing-real-change-to-any-business-or-organization-js" */),
  "component---src-pages-blog-the-importance-of-performance-metrics-measurable-data-is-manageable-data-js": () => import("/opt/build/repo/src/pages/blog/the-importance-of-performance-metrics-measurable-data-is-manageable-data.js" /* webpackChunkName: "component---src-pages-blog-the-importance-of-performance-metrics-measurable-data-is-manageable-data-js" */),
  "component---src-pages-blog-using-rpa-to-improve-workflow-in-the-insurance-industry-js": () => import("/opt/build/repo/src/pages/blog/using-rpa-to-improve-workflow-in-the-insurance-industry.js" /* webpackChunkName: "component---src-pages-blog-using-rpa-to-improve-workflow-in-the-insurance-industry-js" */),
  "component---src-pages-business-consulting-js": () => import("/opt/build/repo/src/pages/business-consulting.js" /* webpackChunkName: "component---src-pages-business-consulting-js" */),
  "component---src-pages-business-consulting-business-strategy-js": () => import("/opt/build/repo/src/pages/business-consulting/business-strategy.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-js" */),
  "component---src-pages-business-consulting-business-strategy-business-architecture-js": () => import("/opt/build/repo/src/pages/business-consulting/business-strategy/business-architecture.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-business-architecture-js" */),
  "component---src-pages-business-consulting-business-strategy-customer-journey-mapping-and-experience-js": () => import("/opt/build/repo/src/pages/business-consulting/business-strategy/customer-journey-mapping-and-experience.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-customer-journey-mapping-and-experience-js" */),
  "component---src-pages-business-consulting-business-strategy-value-targeting-and-business-analysis-js": () => import("/opt/build/repo/src/pages/business-consulting/business-strategy/value-targeting-and-business-analysis.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-value-targeting-and-business-analysis-js" */),
  "component---src-pages-business-consulting-business-strategy-vision-and-strategy-management-js": () => import("/opt/build/repo/src/pages/business-consulting/business-strategy/vision-and-strategy-management.js" /* webpackChunkName: "component---src-pages-business-consulting-business-strategy-vision-and-strategy-management-js" */),
  "component---src-pages-business-consulting-enterprise-program-management-js": () => import("/opt/build/repo/src/pages/business-consulting/enterprise-program-management.js" /* webpackChunkName: "component---src-pages-business-consulting-enterprise-program-management-js" */),
  "component---src-pages-business-consulting-operational-and-process-excellence-js": () => import("/opt/build/repo/src/pages/business-consulting/operational-and-process-excellence.js" /* webpackChunkName: "component---src-pages-business-consulting-operational-and-process-excellence-js" */),
  "component---src-pages-business-consulting-operational-and-process-excellence-business-process-improvement-js": () => import("/opt/build/repo/src/pages/business-consulting/operational-and-process-excellence/business-process-improvement.js" /* webpackChunkName: "component---src-pages-business-consulting-operational-and-process-excellence-business-process-improvement-js" */),
  "component---src-pages-business-consulting-operational-and-process-excellence-performance-measurement-js": () => import("/opt/build/repo/src/pages/business-consulting/operational-and-process-excellence/performance-measurement.js" /* webpackChunkName: "component---src-pages-business-consulting-operational-and-process-excellence-performance-measurement-js" */),
  "component---src-pages-business-consulting-operational-and-process-excellence-process-and-capability-assessment-js": () => import("/opt/build/repo/src/pages/business-consulting/operational-and-process-excellence/process-and-capability-assessment.js" /* webpackChunkName: "component---src-pages-business-consulting-operational-and-process-excellence-process-and-capability-assessment-js" */),
  "component---src-pages-business-consulting-people-and-change-consulting-js": () => import("/opt/build/repo/src/pages/business-consulting/people-and-change-consulting.js" /* webpackChunkName: "component---src-pages-business-consulting-people-and-change-consulting-js" */),
  "component---src-pages-contact-us-js": () => import("/opt/build/repo/src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-consulting-services-js": () => import("/opt/build/repo/src/pages/digital-consulting-services.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-js" */),
  "component---src-pages-digital-consulting-services-customer-experience-and-design-js": () => import("/opt/build/repo/src/pages/digital-consulting-services/customer-experience-and-design.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-customer-experience-and-design-js" */),
  "component---src-pages-digital-consulting-services-digital-strategy-js": () => import("/opt/build/repo/src/pages/digital-consulting-services/digital-strategy.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-digital-strategy-js" */),
  "component---src-pages-digital-consulting-services-digital-technology-services-js": () => import("/opt/build/repo/src/pages/digital-consulting-services/digital-technology-services.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-digital-technology-services-js" */),
  "component---src-pages-digital-consulting-services-test-automation-and-devops-success-js": () => import("/opt/build/repo/src/pages/digital-consulting-services/test-automation-and-devops-success.js" /* webpackChunkName: "component---src-pages-digital-consulting-services-test-automation-and-devops-success-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industry-expertise-js": () => import("/opt/build/repo/src/pages/industry-expertise.js" /* webpackChunkName: "component---src-pages-industry-expertise-js" */),
  "component---src-pages-technology-services-js": () => import("/opt/build/repo/src/pages/technology-services.js" /* webpackChunkName: "component---src-pages-technology-services-js" */),
  "component---src-pages-technology-services-cloud-computing-js": () => import("/opt/build/repo/src/pages/technology-services/cloud-computing.js" /* webpackChunkName: "component---src-pages-technology-services-cloud-computing-js" */),
  "component---src-pages-technology-services-data-and-analytics-js": () => import("/opt/build/repo/src/pages/technology-services/data-and-analytics.js" /* webpackChunkName: "component---src-pages-technology-services-data-and-analytics-js" */),
  "component---src-pages-technology-services-devops-js": () => import("/opt/build/repo/src/pages/technology-services/devops.js" /* webpackChunkName: "component---src-pages-technology-services-devops-js" */),
  "component---src-pages-technology-services-enterprise-applications-and-solutions-js": () => import("/opt/build/repo/src/pages/technology-services/enterprise-applications-and-solutions.js" /* webpackChunkName: "component---src-pages-technology-services-enterprise-applications-and-solutions-js" */),
  "component---src-pages-technology-services-enterprise-collaboration-js": () => import("/opt/build/repo/src/pages/technology-services/enterprise-collaboration.js" /* webpackChunkName: "component---src-pages-technology-services-enterprise-collaboration-js" */),
  "component---src-pages-technology-services-it-strategy-js": () => import("/opt/build/repo/src/pages/technology-services/it-strategy.js" /* webpackChunkName: "component---src-pages-technology-services-it-strategy-js" */),
  "component---src-pages-technology-services-mobile-app-development-js": () => import("/opt/build/repo/src/pages/technology-services/mobile-app-development.js" /* webpackChunkName: "component---src-pages-technology-services-mobile-app-development-js" */),
  "component---src-pages-technology-services-modern-software-delivery-js": () => import("/opt/build/repo/src/pages/technology-services/modern-software-delivery.js" /* webpackChunkName: "component---src-pages-technology-services-modern-software-delivery-js" */),
  "component---src-pages-technology-services-outsourcing-js": () => import("/opt/build/repo/src/pages/technology-services/outsourcing.js" /* webpackChunkName: "component---src-pages-technology-services-outsourcing-js" */),
  "component---src-pages-thank-you-js": () => import("/opt/build/repo/src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

